// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import "./controllers"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

require("./packs/header")
require("./packs/form")
require("./packs/services-filter")
require("./packs/slider")
require("./packs/faq")
require("./packs/scroll")
require("./packs/btn_to_top")
