// document.addEventListener('DOMContentLoaded', function () {

// 	var defaultFilter = document.querySelector('#heating');
// 	if (defaultFilter) {
// 		filterCards(defaultFilter);
// 	}

// 	var filterItems = document.querySelectorAll('.filter-item');

// 	filterItems.forEach(function (item) {
// 		item.addEventListener('click', function () {
// 			filterItems.forEach(function (item) {
// 				item.classList.remove('active');
// 			});
// 			this.classList.add('active');
// 			filterCards(this);
// 		});
// 	});

// 	function filterCards(filter) {
// 		if (!filter) return; // Add this check
// 		var filterClass = filter.getAttribute('id');
// 		if (!filterClass) return; // Add this check

// 		var serviceCards = document.querySelectorAll('.service-card');
// 		serviceCards.forEach(function (card) {
// 			if (card.classList.contains(filterClass)) {
// 				card.style.display = 'block';
// 			} else {
// 				card.style.display = 'none';
// 			}
// 		});
// 	}
// });

