import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';

var swiper = new Swiper('.reviews-swiper', {
	modules: [Navigation, Autoplay],
	loop: true,
	slidesPerView: "auto",
	spaceBetween: 40,
	speed: 500,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	// autoplay: {
	// 	delay: 3000,
	// 	disableOnInteraction: false
	// }
});

var swiperBrands = new Swiper('.brands-slider', {
	modules: [Autoplay],
	loop: true,
	slidesPerView: "auto",
	speed: 3500,
	autoplay: {
		delay: 0,
		disableOnInteraction: false,
	},
});

var servicesSwiper = new Swiper('.services-swiper', {
	modules: [Navigation],
	slidesPerView: 1,
	spaceBetween: 30,
	speed: 500,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		600: {
			slidesPerView: 2,
		},
		1025: {
			slidesPerView: 4,
			spaceBetween: 40,
		}
	},
});